
//Desktop Scss

@media screen and (min-width: 787px){
.Page{
    display: contents;

    max-width: 100vw;
    height: 100%;
        
}

.monkey{
    background:transparent; clear:left; font:14px Helvetica,Arial,sans-serif;  width:600px;
}

.PageM{
    display: none;
}

.subscribediv{
    display:flex ;
    width: 30vw;
    margin: auto;
    text-align: center;
    justify-content: center;
}



.NavBarRow{
    background-size: 100vw 100%;

    background-image: url("../Assets/header.webp");

        background-repeat: no-repeat;
}

.LogoImg{
    margin-left: 20px;
    margin-top: 10px;
height: 80px;
}

.SocImg{
    margin-top: 20px;
position: relative;
height: 50px;

}

.LogoImgm{
    margin-left: 10px;
height: 50px;
}

.SocImgm{
    margin-top: 20px;
position: relative;
height: 20px;

}

.SocImg:first-child{
padding-right: 30px;
}

.WelcomeMeadow{
    background-image: url("../Assets/meadow_moon_background.webp");
    background-size: 100vw;
    background-repeat: no-repeat;
    height: 64.81770833333333vw;
}

.align-items-end{
    text-align: end;
}

.soccol{
    margin-right: 50px;
}

.MainLogo{
    display: flex;
    width: 70%;
    margin: auto;
}

.CloudBG{

    position: relative;
    bottom: 12vw;
    
    background-image: url("../Assets/blue_bg.webp");
    background-size: 100vw 100%;

    background-repeat: no-repeat;
    height: 25VW;
    z-index: 2;

}

.Clouds{
    position: relative;
    top: 24%;
    background-image: url("../Assets/cloud_1.svg"), url("../Assets/cloud_2.svg"), url("../Assets/cloud_3.svg"); 
    background-size: 100vw 100%;
    background-position:  bottom;
    z-index: 4;
    background-repeat: no-repeat;
    height: 37.84974093264249vw;
}

.WelcomeH1{
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 600;
    font-size: 9vw;
    line-height: 8vw;
    text-align: center;
    
    color: #403E4D;
    
}

.WelcomeTxt{
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 400;
    font-size: 2.1vw;
    line-height: 2vw;
    /* or 117% */
    width: 65vw;
    text-align: center;
    margin: auto;
    color: #403E4D;
    
}

.Welcometo{
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 600;
    font-size: 4vw;
    line-height: 6vw;
    text-align: center;
    
    color: #403E4D;
}

.GameplayBG{
    
    background-size: 140vw;
    background-position: center;
    background-repeat: no-repeat;
    height: 66.25vw;
    z-index: 3;

    background-image: linear-gradient(
        to bottom, transparent 98%, rgba(255, 255, 255, .99) , rgba(255, 255, 255, 1)
      ),  url("../Assets/hubworld_bg.webp");
}

.darksht{
    position: relative;
    top: 40px;
    background-image: url("../Assets/Gameplay_text_bg.webp");
    background-size: 72.03125vw;
    margin: auto;
    max-width: 72.03125VW !important;
    height:  67.13665943600867vw;
   
    background-repeat: no-repeat;
}

.GameplayH1{
   padding-top: 15vw;
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 600;
    font-size: 7vw;
    line-height: 8vw;
    text-align: center;
    color: white;
}

.GameplayTxt{
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 400;
    font-size: 1.8vw;
    line-height: 2vw;
    /* or 117% */
    width: 55vw;
    text-align: center;
    margin: auto;
    color: white;
}

.GameplayCard{
    position: relative;
    bottom: 19vw;
    background-image: url('../Assets/black_box.svg') !important;
    width: 26.26943005181347vw !important;
    height: 36.52849740932643vw !important;
    z-index: 10 !important;
}

.middlecard{
    position: relative;

    bottom: 23vw;
}

.CardRow{

    gap: 40px;
    justify-content: center;
}

.farmingcardtxt{
    font-family: 'Mitr' !important;
font-style: normal !important;
font-weight: 600 !important;
font-size: 3vw !important;
line-height: 3vw !important;
/* or 100% */

text-align: center !important;

color: #64A2F1 !important;
}

.fishingcardtxt{
    font-family: 'Mitr' !important;
font-style: normal !important;
font-weight: 600 !important;
font-size: 3vw !important;
line-height: 3vw !important;
/* or 100% */

text-align: center !important;

color: #B2C643 !important;
}

.dungeoncardtxt{
    font-family: 'Mitr' !important;
font-style: normal !important;
font-weight: 600 !important;
font-size: 3vw !important;
line-height: 3vw !important;
/* or 100% */

text-align: center !important;

color: #D66262 !important;

}


.carddescript{
    margin-top: 1vw;
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5vw;
    line-height:1.8vw;
    /* or 140% */
    
    text-align: center;
    color: #FFFFFF;
}

.coopcon{
margin-top: 22vw;
}

.coophead{
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 700;
    font-size: 6vw;
    line-height: 8vw;
    text-align: center;
    color: #403E4D;
}

.cooptxt{
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 700;
    font-weight: 400;
    font-size: 1.7vw;
    line-height: 2vw;
    /* or 117% */
    width: 55vw;
    margin: auto;
    text-align: center;
    color: #403E4D;
}

.CharacterCon{
    position: relative;
    background-image: url('../Assets/character_bg.webp');
    background-repeat:no-repeat;
    background-size: 100vw;
    height: 61.50259067357513vw;
}

.charimg{

    position: absolute;
    right: 0;
    bottom: 0;
width: 68.54922279792746vw;
}

.imgcon{

    position: absolute;
    right: 0;
    bottom: 0;

}


.charhead{
    margin-top: 10vw;
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 700;
    font-size: 6vw;
    line-height: 8vw;
    text-align: center;
    color: #403E4D;
}

.charcloudimg{
    width: 100%;


    position: absolute;
    right: 0;
    bottom: -4vw;
}

.imgwarp{

}

.chartxt{
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 700;
    font-weight: 400;
    font-size: 1.7vw;
    line-height: 2vw;
    /* or 117% */
    margin: auto;
    text-align: center;
    color: #403E4D;
    width: 55vw;

}

.fooot{
    position: relative;
    background-image: url('../Assets/community_bg.webp');
    background-repeat:no-repeat;
    background-size: 100vw;
    z-index: -1;
    height: 62.383419689119165vw;
    
}

.foootsoc{
position: absolute;
top: 70%;
left: 50%;
transform: translate(-50%, -50%);
text-align: center;
margin-top: 4vw;

}

.socfoot{

    height: 6vw;
    width: 6vw;
    
}

     
.soclink{
    position: relative;
    z-index: 8;
}

.socfoot:first-of-type{
    padding-right: 1.8vw;
    height: 8vw;
    width: 8vw;
    
}

.foottxt{
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 600;
    font-size: 1.8vw;
    line-height: 1.8vw;
    text-align: center;
    
    color: #000000;
     
}

.footpicR{
    position: absolute !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 33.86010362694301vw !important;
}

.footpicL{
    position: absolute !important;
    left: 0 !important;
    bottom: 0 !important;
    width: 33.86010362694301vw !important;
}

.EmailSub{
   
    position: absolute;
    top: 12vw;
}

.SubscribeBtn{
display: flex;
background: url('../Assets/submitbtn.png');
background-size: contain;
width: 16vw;
height: 8vw;
background-repeat: no-repeat;
border: none;
-webkit-filter: brightness(100%);
margin: auto;
 &:hover{
    -webkit-filter: brightness(50%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
 }
}

.EmailInput{
    width: 50% !important;
    margin: auto;
}

.sublabel{
    display: flex;
    width: 30%;
    margin:auto;
    font-size: 1.5vw;
    line-height: 1.5vw;
    text-align: center;
}

.foootrow{
    position: relative;
    top: 10vw;
}

.foooth1{
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 600;
    font-size: 3vw;
    line-height: 4vw;
    /* or 125% */
    width: 50% !important;

    display: flex !important;
    margin: auto;
    align-self: center !important;
    text-align: center !important;
    position: relative;
    left: 4.5vw;
}


.SignUpBtn{
    display: flex;
background: url('../Assets/Sign.png');
background-size: contain;
width: 30vw;
height: 10vw;
background-repeat: no-repeat;
border: none;
-webkit-filter: brightness(100%);
margin: auto;
 &:hover{
    -webkit-filter: brightness(50%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
 }
 &:active{
    -webkit-filter: invert(100%);
 }
 
position: relative;
top: -180px;
}

}



//Mobile Scss

@media screen and (max-width: 786px){
    .Page{
       
     display: none;
        
    }

    .PageM{
       display: contents;
        max-width: 100vw;
        height: 100%;
        overflow: hidden;
    }

    .NavBarRow{
        background-size: 100vw 100%;

        background-image: url("../Assets/header.webp");

            background-repeat: no-repeat;
    }

    .LogoImg{
        margin-left: 10px;
    height: 60px;
    }

    .SocImg{
        margin-top: 20px;
    position: relative;
    height: 30px;

    }


    .SocImg:first-of-type{
    padding-right: 15px;
    }

    .WelcomeMeadow{
        position: relative;

        background-image: url("../Assets/mob/meadow_moon_background.webp");
        background-position: center;
        background-size: 100vw;
        background-repeat: no-repeat;
        height: 114.81770833333333vw;
        z-index: 1;
    }
    

    .align-items-end{
        text-align: end;
    }



    .MainLogo{
        position: absolute;
        width: 92vw;
        left: 4vw;
        top: -25vw;
    }

    .CloudBG{

        position: relative;

        background-image: url("../Assets/mob/blue_fade 1.webp");
        background-size: 100vw 100%;

        background-repeat: no-repeat;
        height: 90VW;
        z-index: -1;

    }

    .Clouds{
        position: absolute;
        bottom: -9.5vh;
        text-align: center;
        background-image: url("../Assets/mob/Cloud_1_mobile.svg"); 
        background-size: 100vw 100%;    
        background-position:  bottom;
        z-index: 4;
        width: 100vw;
        background-repeat: no-repeat;
        height: 37.84974093264249vw;
    }

    .WelcomeH1{
        font-family: 'Mitr';
        font-style: normal;
        font-weight: 600;
        font-size: 9vw;
        line-height: 8vw;
        text-align: center;
        
        color: #403E4D;
        
    }

    .WelcomeTxt{

        font-family: 'Mitr';
        font-style: normal;
        font-weight: 400;
        font-size: 3.3vw;
        line-height: 3.3vw;
        /* or 120% */
        

        
        color: #FFFFFF;
        
        width: 65vw;
        text-align: center;
        margin: auto;

        
    }

    .Welcomewrap{
        position: absolute;
        top: 30vw;
    }

    .Welcometo{
        font-family: 'Mitr';
        font-style: normal;
        font-weight: 600;
        font-size: 4vw;
        line-height: 6vw;
        text-align: center;
        
        color: #403E4D;
    }

    .GameplayBG{
        position: relative;
        top: -15vw;
        background-size: 100vw;
        background-position: center;
        background-repeat: no-repeat;
        height: 393.3021806853583vw;
        z-index: -3;
        text-align: center;
        background-image: url("../Assets/mob/hubworld_mobile.webp");
    }

    .darksht{
        position: relative;
        bottom: -50vw;
        background-image: url("../Assets/mob/Gameplay_text_bg.webp");
        background-size: 100vw 100%;
        margin: auto;
        max-width: 100VW !important;
        height:  100vw;
    
        background-repeat: no-repeat;
    }

    .GameplayH1{
    padding-top: 25vw;
        font-family: 'Mitr';
        font-style: normal;
        font-weight: 600;
        font-size: 11vw;
        line-height: 10vw;
        text-align: center;
        color: white;
    }

    .GameplayTxt{
        font-family: 'Mitr';
        font-style: normal;
        font-weight: 400;
        font-size: 2.8vw;
        line-height: 3.2vw;
        /* or 117% */
        width: 55vw;
        text-align: center;
        margin: auto;
        color: white;
    }

    .GameplayCard{
        position: absolute;
        bottom: -25vw;
        margin-top: 5vw;
        background-image: url('../Assets/mob/blackbox.webp') !important;
        width: 71.80685358255452vw !important;
        height: 65.86604361370716vw !important;
        z-index: 10 !important;
    }

    .middlecard{

    }

    .CardRow{
        position: absolute;
        bottom: -35vw;
        left: 50%;
        
        transform: translate(-50%, -50%);
        margin: 0 auto;
    }

    .farmingcardtxt{
        font-family: 'Mitr' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 5vw !important;
    line-height: 4vw !important;
    /* or 100% */

    text-align: center !important;

    color: #64A2F1 !important;
    }

    .fishingcardtxt{
        font-family: 'Mitr' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 5vw !important;
    line-height: 4vw !important;
    /* or 100% */

    text-align: center !important;

    color: #B2C643 !important;
    }

    .dungeoncardtxt{
        font-family: 'Mitr' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 5vw !important;
    line-height: 4vw !important;
    /* or 100% */

    text-align: center !important;

    color: #D66262 !important;

    }


    .carddescript{
        margin-top: 1vw;
        font-family: 'Mitr';
        font-style: normal;
        font-weight: 400;
        font-size: 2.5vw;
        line-height:2.5vw;
        /* or 140% */
        
        text-align: center;
        color: #FFFFFF;
    }

    .coopcon{
    margin-top: -15vw;
    }

    .coophead{
        font-family: 'Mitr';
        font-style: normal;
        font-weight: 900;
        font-size: 8vw;
        line-height: 8vw;
        text-align: center;
        color: #403E4D;
    }

    .freindimg{
        width: 100vw;
    }

    .cooptxt{
        font-family: 'Mitr';
        font-style: normal;
        font-weight: 700;

        font-size: 3vw;
        line-height: 3vw;
        /* or 117% */
        width: 60vw;
        margin: auto;
        text-align: center;
        color: #403E4D;
    }

    .CharacterCon{
        position: relative;
        background-image: url('../Assets/character_bg.webp');
        background-repeat:no-repeat;
        background-size: 100vw;
        height: 61.50259067357513vw;
    }

    .charimg{

        position: absolute;
        right: 0;
        bottom: 0;
    width: 68.54922279792746vw;
    }

    .imgcon{

        position: absolute;
        right: 0;
        bottom: 0;

    }


    .charhead{
        margin-top: 2vw;
        font-family: 'Mitr';
        font-style: normal;
        font-weight: 900;
        font-size: 8vw;
        line-height: 8vw;
        text-align: center;
        color: #403E4D;
    }

    .charcloudimg{
        width: 100%;


        position: absolute;
        right: 0;
        bottom: -4vw;
    }

    .imgwarp{

    }

    .chartxt{
        font-family: 'Mitr';
        font-style: normal;
        font-weight: 700;

        font-size: 3.5vw;
        line-height: 3vw;
        /* or 117% */
        margin: auto;
        text-align: center;
        color: #403E4D;
        width: 90vw;

    }

    .fooot{
        position: relative;
        background-image: url('../Assets/community_bg.webp');
        background-repeat:no-repeat;
        background-size: 100vw;
        z-index: -1;
        height: 62.383419689119165vw;
        width: 100vw;
    }

    .foootsoc{
    position: absolute;
    top: 94%;
    left: 50%;
    transform: translate(-50%, -75%);
    text-align: center;
    margin-top: 4vw;

    }
     
    .soclink{
        display: inline-flex;
        position: relative;
        height: 13vw;
        z-index: 99;

    }

    .socfoot{

        margin-right: 1.8vw;
        height: 10vw;
        width: 10vw;
        z-index: 0;
    }

    .foottxt{
        font-family: 'Mitr';
        font-style: normal;
        font-weight: 600;
        font-size: 4vw;
        line-height: 4vw;
        text-align: center;
        
        color: #000000;
        
    }

    .footpicR{
        position: absolute !important;
        right: 0 !important;
        bottom: 0 !important;
        width: 33.86010362694301vw !important;
    }

    .footpicL{
        position: absolute !important;
        left: 0 !important;
        bottom: 0 !important;
        width: 33.86010362694301vw !important;
    }

    .footlinkrow{
        display: inline;
    }



    .SignUpBtn{
        display: flex;
    background: url('../Assets/Sign.png');
    background-size: contain;
    width: 40vw;
    height: 10vw;
    background-repeat: no-repeat;
    border: none;
    -webkit-filter: brightness(100%);
    margin: auto;
     &:hover{
        -webkit-filter: brightness(50%);
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -o-transition: all 1s ease;
        -ms-transition: all 1s ease;
        transition: all 1s ease;
     }
     &:active{
        -webkit-filter: invert(100%);
     }
     
    position: relative;
     top: 38vw;
     
    }

    
.EmailSub{
   
    position: absolute;
    top: 12vw;
}

.SubscribeBtn{
display: flex;
background: url('../Assets/submitbtn.png');
background-size: contain;
width: 20vw;
height: 8vw;
background-repeat: no-repeat;
border: none;
-webkit-filter: brightness(100%);
margin: auto;
 &:hover{
    -webkit-filter: brightness(50%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
 }
 &:active{
    -webkit-filter: invert(100%);
 }
 

}

.mtop{
    margin-top: 600px;
}
.EmailInput{
    border-radius: 10px !important;
    width: 70% !important;
    margin: auto;
    margin-bottom: 1vw;
}

.sublabel{
    display: flex;
    width: 55%;
    margin:auto;
    font-size: 3vw;
    line-height: 3vw;
    text-align: center;
}

.foootrow{
    position: relative;
    bottom: -5vw;
}

.foooth1{
    font-family: 'Mitr';
    font-style: normal;
    font-weight: 600;
    font-size: 5vw;
    line-height: 5vw;
    /* or 125% */
    width: 70% !important;

    display: flex !important;
    margin: auto;
    align-self: center !important;
    text-align: center !important;
    position: relative;
    left: 4.5vw;
}
}